<template>
  <div class="readme-article">
    <h1 id="客户群运营">五、客户群运营</h1>
    <h2 id="客户群列表">1.客户群列表</h2>
    <h3 id="客户群数据">1.1客户群数据</h3>
    <p>
      查看客户群列表，包含群名称、群主、群标签、群人数等信息，点击详情按钮可查看群详情。
    </p>
    <p>
      <img src="@/assets/img/scrm/5-1.png" />
    </p>
    <h3 id="客户群详情">1.1客户群详情</h3>
    <p>
      查看客户群详情，可编辑群标签，查看群人员详情。
    </p>
    <p>
      <img src="@/assets/img/scrm/5-2.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Scrm5-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>